import React from 'react'
import Grid from '@material-ui/core/Grid'
import styles from './Body-hr.module.css'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grow from '@material-ui/core/Grow';

const humanResourcesSection = {
  // 'COVID-19 Symptom Questionnaire': {
  //   href: 'https://blogs.molloy.edu/covid-19-questionnaire',
  //   img: require('../../../../assets/covid_questionnaire.png'),
  //   p: "This form must be filled out before each visit to Molloy's campuses."
  // },
  'AAUP - American Association of University Professors/Survey': {
    href: 'https://research.aaup.org/',
    img: require('../../../../assets/aaup.png'),
    p: ""
  },
  "Access": {
    href: 'https://www.microsoft.com/en-us/microsoft-365/access',
    img: require('../../../../assets/access.png'),
    p: ""
  },
  "ADP": {
    href: 'https://online.adp.com/signin/v1/?APPID=WFNPortal&productId=80e309c3-7085-bae1-e053-3505430b5495&returnURL=https://workforcenow.adp.com/&callingAppId=WFN',
    img: require('../../../../assets/adp.png'),
    p: ''
  },
  'Calendly': {
    href: 'https://calendly.com/login',
    img: require('../../../../assets/calendy.png'),
    p: ""
  },
  "Cigna": {
      href: 'https://cignaforemployers.cigna.com/public/app/signin',
      img: require('../../../../assets/cigna.png'),
      p: ""
  },   
  'Concord': {
    href: 'https://secure.concordnow.com/#/login',
    img: require('../../../../assets/concord.png'),
    p: ""
  },
  'CUPA HR': {
    href: 'https://www.cupahr.org/',
    img: require('../../../../assets/cupa.png'),
    p: ""
  },
  'Department of Labor - Unemployment': {
    href: 'https://dol.ny.gov/',
    img: require('../../../../assets/department_of_labor.png'),
    p: ""
  },
  'Diverse Jobs': {
    href: 'https://diversejobs.net/',
    img: require('../../../../assets/diverse_jobs.png'),
    p: ""
  },
  'EmblemHealth': {
    href: 'https://portals5.emblemhealth.com/Groups/Login.aspx?ReturnUrl=%2fGroups',
    img: require('../../../../assets/emblemHealth.png'),
    p: ""
  },
  'Empire': {
    href: 'https://www.empireblue.com/',
    img: require('../../../../assets/beacon.jpg'),
    p: ""
  },
  'HERC - Higher Education Recruitment Consortium': {
    href: 'https://www.hercjobs.org/',
    img: require('../../../../assets/hercjobs.png'),
    p: ""
  },
  'HigherEdJobs': {
    href: 'https://www.higheredjobs.com/',
    img: require('../../../../assets/higher_ed_jobs.png'),
    p: ""
  },
  'Legal Shield': {
    href: 'https://classic.shieldatwork.com/init',
    img: require('../../../../assets/legal_shield.png'),
    p: ""
  },
  'LibApps': {
    href: 'https://molloy-blended-online.libapps.com/libapps/login.php?site_id=8789&target=%2Fadmin%2Fcalendar%2F3807%2Fevent%2F6130125%2Fregistrations',
    img: require('../../../../assets/libapps.png'),
    p: ""
  },
  'LinkedIn': {
    href: 'https://www.linkedin.com/uas/login',
    img: require('../../../../assets/linkedin.png'),
    p: ""
  },
  'MetLife': {
    href: 'https://online.metlife.com/edge/web/public/uslogin?accesstype=employer',
    img: require('../../../../assets/metlife.png'),
    p: ""
  },
  'NAVEX Ethics Point': {
    href: 'https://secure.ethicspoint.com/domain/en/default_reporter.asp',
    img: require('../../../../assets/navex.png'),
    p: ""
  },
  'Prudential': {
    href: 'https://ssologin.prudential.com/app/giportal/Login.fcc?TYPE=33554433&REALMOID=06-0009587c-8651-1eed-a89e-348e307ff004&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=giportal&TARGET=-SM-https%3a%2f%2fgiportal%2eprudential%2ecom%2fERPortalLoginHelper%2flogin%2ehtm',
    img: require('../../../../assets/prudential.png'),
    p: ""
  },
  'Pyramed - Vaccine Portal': {
    href: 'https://p5.pyramed.online/logon/LogonPoint/tmindex.html',
    img: require('../../../../assets/pyramed.png'),
    p: ""
  },
  'SHRM - Society for Human Resources Management': {
    href: 'https://login.shrm.org/?request_id=id3D65276BF9E1B6&relay_state=id-dab89e89-111c-472c-acb7-be25fc739946&issuer=aHR0cHM6Ly9zc28uc2hybS5vcmcvSURCVVMvU0hSTS9JRFAvU0FNTDIvTUQ=&target=aHR0cHM6Ly9zc28uc2hybS5vcmcvSURCVVMvU0hSTS9LQUZLQS1NWS9TQU1MMi9NRA==',
    img: require('../../../../assets/shrm.png'),
    p: ""
  },
  'TIAA': {
    href: 'https://www.tiaa.org/public/',
    img: require('../../../../assets/tiaa.png'),
    p: ""
  },
  'Union': {
    href: 'https://www.32bjfundsemployer.org/',
    img: require('../../../../assets/union.png'),
    p: ""
  },
  'Vector Solutions': {
    href: 'https://molloy-ny.safecolleges.com/admin/main#',
    img: require('../../../../assets/vector_solutions.png'),
    p: ""
  },
  'WageWorks': {
    href: 'https://wageworks.com',
    img: require('../../../../assets/wageworks.png'),
    p: ""
  },
  'Worker\'s Compensation': {
    href: 'http://www.wcb.ny.gov/',
    img: require('../../../../assets/workers_compensation.png'),
    p: ""
  },
  'Zoom': {
    href: 'https://zoom.us/',
    img: require('../../../../assets/zoom.png'),
    p: ""
  }
}

const generateCards = (sectionObj) => {
  /*
    <p>
                                  {sectionObj[siteName].p}
                                </p>
  */
    return Object.keys(sectionObj).map((siteName) => {
        return (
        <Grid key={siteName} item xs={12} sm={12} md={12} lg={4} className={styles.cardsContainer}>
            <Grow direction="up" in={true} timeout={{enter: 900, exit: 0}}>
                <Card className={styles.card} >
                    <CardActionArea target='_blank' rel='noopener noreferrer' href={sectionObj[siteName].href}>
                        <div className={styles.cardLayout}>
                            <CardMedia
                              component="img"
                              alt={siteName}
                              className={styles.media}
                              //height="220"
                              src={sectionObj[siteName].img}
                              title={siteName}
                              />
                              <div className={styles.cardContent}> 
                                <h4 className={styles.linkLabel}>{siteName}</h4>
                              </div>
                        </div>
                    </CardActionArea>
                </Card>
             </Grow>
        </Grid>
        )
    })
}

const Body = () => {
  return (
  <Grid container className={styles.bodyContainer}>
    <Grid item xs={12} sm={12} md={12}>
      <h1 style={{textAlign: "center"}}>Human Resources</h1>
        <hr style={{width: '80%'}}/>
      <Grid container justify="center">
        {generateCards(humanResourcesSection)}
      </Grid>
    </Grid>
  </Grid>
  )
}

export default Body