import React from 'react'
import  ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { render } from 'react-dom'
import App from "./App";

import Footer from './Components/Layouts/Footer/Footer'
import Header from './Components/Layouts/Header/Header'
import BodyHr from './Components/Layouts/Routes/hr/Body-hr.js'
import BodyStudentsEmployees from './Components/Layouts/Routes/students-employees/Body-students-employees.js'

// const root = document.getElementById("root")

// render(<App />, root);

ReactDOM.render(

    <React.StrictMode>
  
      <Router>
        <Header/>
  
        <Routes>
          <Route path="/" element={<BodyStudentsEmployees />} />
          <Route path="/hr" element={<BodyHr />} />
        </Routes>

        <Footer/>
      </Router>
  
    </React.StrictMode>,
  
     document.getElementById('root')
  );

